import React, { useEffect, useState } from "react";
import "./asideleft.css"


export default function AsideLeftQR({ }) {

    return (
        <div className="asideLeftQR">
          <br /><br />
          <img className='asideLeftLogo' src={`${process.env.REACT_APP_FRONT}/torneosWarzone.png`} alt="torneoswarzone" />
          <br /><br />
          <h3 className="green">¡DESCÁRGATELA YA!</h3>
          <br /><br />
          <img className='codigoqr' src={`${process.env.REACT_APP_FRONT}/codigoqr.png`} alt="qrcode" />
          <a className='playstore' href="https://play.google.com/store/apps/details?id=com.app.torneoswarzone&hl=es_419&gl=US"><img className='codigoqr' src={`${process.env.REACT_APP_FRONT}/google-play.svg`} alt="playstore" /></a>
        </div>
    );
}

