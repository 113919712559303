import React from "react";
import "./asideleft.css"
import AsideLeftQR from "./AsideLeftQR"

export default function AsideLeftTeams({ }) {
    return (
        <aside className="asideLeft">
            <div className="asideLeft_container">
                <br />
                <header>
                    <h1 className="permanent green">Únete o Forma Tu Propio Equipo de Warzone</h1>
                </header>
                <hr className="separator" />
                <div>
                    <h2 className="permanent gold">¿Buscas Equipos de Warzone?</h2>
                    <br />
                    <p><b>Únete a un equipo de Call of Duty: Warzone</b> y juega con otros entusiastas del juego.</p>
                    <br />
                    <p>Mejora tu estrategia y coordinación en equipo.</p>
                    <br />
                    <p>¿Prefieres ser líder?</p>
                    <br />
                    <p><b>Crea tu propio equipo y recluta a los mejores jugadores</b> para competir en torneos.</p>
                    <br /><br />
                    <h3>Ventajas de Unirte a un Equipo:</h3>
                    <br />
                    <ul>
                        <li>- Mejora la comunicación y estrategia</li>
                        <li>- Participa en torneos en equipo</li>
                        <li>- Construye una comunidad de jugadores</li>
                    </ul>
                    <br /><br />
                    <h3>Comparte Tu Código de Invitación</h3>
                    <br />
                    <p>Para unirse a un equipo, necesitas un código de invitación.</p>
                    <br />
                    <p><b>Comparte tu código</b> con otros jugadores para que se unan a tu equipo.</p>
                    <br />
                    <p><b>¿Tienes un código de invitación?</b> Úsalo para unirte a un equipo y comenzar a jugar juntos.</p>
                </div>
                <AsideLeftQR />
            </div>
        </aside>
    );
}
