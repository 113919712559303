import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getTournaments } from "../../../services/tournament.service";
import { getMaps } from "./../../../services/map.service"
import TournamentItem from "../item/tournamentItem";
import Chat from "../../chats/Chat";
import "./tournamentList.css";
import AsideLeftTournaments from "../../asides/asideLeft/AsideLeftTournaments"


export default function TournamentList({ }) {
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [tournaments, setTournaments] = useState([]);
  const [lastList, setLastList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [maps, setMaps] = useState([]);

  const listInnerRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {

    const profile = JSON.parse(localStorage.getItem("user"));
    if (profile != null) {
      if (maps.length === 0) {
        fetchMaps();
      }
      if (!lastList && prevPage !== currPage) {
        fetchTournaments(maps);
      }
    }
  }, [currPage, lastList, prevPage, tournaments]);

  useEffect(() => {
    setIsLoading(false);
  }, [tournaments]);

  const fetchTournaments = async (maps) => {
    if (isLoading || currPage <= prevPage) return; // Evitar solicitudes duplicadas o regresivas
    setIsLoading(true);
    try {
      const tournamentsDB = await getTournaments(currPage);
      if (tournamentsDB.length === 0) {
        setLastList(true);
        return;
      }
      setPrevPage(currPage);
      for (let tournamentDB of tournamentsDB) { //Asignamos la url de la foto a cada torneo
        for (let map of maps) {
          if (tournamentDB.mapa === map.name) {
            tournamentDB.url = map.url;
          }
        }
      }
      if (currPage !== 1) {
        setTournaments([...tournaments, ...tournamentsDB]);
      } else {
        setTournaments(tournamentsDB);
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchMaps = async () => {
    const mapsDB = await getMaps();
    setMaps(mapsDB);
    fetchTournaments(mapsDB);
  };
  const onScroll = (e) => {
    if (isLoading) return;
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if ((scrollTop + clientHeight + 1) >= scrollHeight) {
        setCurrPage((prevPage) => prevPage + 1); // Utilizar una función de actualización para asegurarse de obtener el valor más actual de currPage
      }
    }
  };

  return (
    <div className="tournaments"
      onScroll={onScroll}
      onTouchMove={onScroll}
      ref={listInnerRef}
      style={{
        height: "100vh", overflowY: "auto"
      }}>
      <div className="list_section">
        <AsideLeftTournaments/>
        <div className="tournaments_section">
          {tournaments.map((tournament, i) => {
            return <div key={i}><TournamentItem key={i} position={i + 1} tournament={tournament} /></div>;
          })}
        </div>
        <aside className="asideRight">
          <Chat />
        </aside>
      </div>
    </div>
  );
}
