
import "./loginitem.css"
import React, { useEffect, useState } from "react";


export default function LoginGoogle({ }) {

  const [token, setToken] = useState([]);

  useEffect(() => {

  }, []);


  return (

    <div className="login_item border_section">
      <div className="login_item_form" >
        <label><b>Inicia sesión con Google</b></label>
        <br />

        <br />
      </div>
    </div>

  );
}

