
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./footer.css";

export default function Footer() {

  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos < currentScrollPos);
      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  return (
    <footer className={visible ? 'footer' : 'footer hidden'}>
      <div className="footer-details">
        <div className='footer-links'>
          <a className='' href="https://play.google.com/store/apps/details?id=com.app.torneoswarzone&hl=es_419&gl=US"><img className='footer_playstore' src={`${process.env.REACT_APP_FRONT}/google-play.svg`} alt="playstore" /></a>
          <Link to="/contacto"><h4>Contacto</h4></Link>
          <Link to="/politicaprivacidad"><h4>Política de Privacidad</h4></Link>
        </div>
      </div>
    </footer>
  );
}
