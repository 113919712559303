
import React, { useEffect, useState } from "react";
import { saveTeam, updateFotoTeam, getTeamByName } from "../../../services/team.service";
import "./formteam.css"

export default function FormTeam() {
    const [name, setName] = useState([]);
    const [details, setDetails] = useState([]);
    const [image, setImage] = useState([]);
    const [token, setToken] = useState([]);
    const [teamNameOcupado, setTeamNameOcupado] = useState(false);


    useEffect(() => {
        setToken(JSON.parse(localStorage.getItem("token")));
    }, []);


    const pathTeams = `${process.env.REACT_APP_API_AWS}/teams`;
    const handleSubmit = async (e) => {
        e.preventDefault();
        const teamDB = await getTeamByName(name);
        if (teamDB.length !== 0) {
            setTeamNameOcupado(true)
        }else {
            setTeamNameOcupado(false)
            const res = await saveTeam(token, name, details);
            if (res.ok) {
                handleSubmitFile(e);
            }
        }
    };
    const handleSubmitFile = async (e) => {
        e.preventDefault();
        const res = await updateFotoTeam(image, token, name);
        if (res.ok) {
            window.location = `/teams/${name}`;
        }
    };
    return (
        <div className="form_team_container">
            <form action={pathTeams} className="form_team border_section" onSubmit={handleSubmit} >
                <div className="form_team_row">
                    <label>Nombre:</label><br />
                    <input type="text" name={name} placeholder="nombre" required onChange={(e) => setName(e.target.value)} />
                </div>
                {teamNameOcupado && <p className="login_item_nickocupado">El nombre ya está en uso</p>}
                <div className="form_team_row">
                    <label>Logo:</label><br />
                    <input className="btn_detail_form_input_file" type="file" name="foto" required onChange={(e) => { setImage(e.target.files[0]) }} />
                </div>
                <span className="span_logo"> Max 1MB </span><br />
                <div className="form_team_row">
                    <label>Detalles:</label><br />
                    <textarea className="form_team_textarea" rows='4' type="text" name={details} placeholder="Detalles del Equipo" required onChange={(e) => setDetails(e.target.value)} ></textarea>
                </div>
                <button type="submit" className="btn_section btn_gold">Aceptar</button>

            </form>
        </div>

    );
}
