
const constants = {
    defaultNick : "¡Escribe tu nick!"
}
const variantsMotion = {
    hidden: { opacity: 0 },
    visible: ({ delay }) => ({
      opacity: 1,
      transition: {
        delay,
        duration: 0.75
      }
    })
  }
export {constants, variantsMotion}