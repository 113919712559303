import React from "react";
import "./asideleft.css"
import AsideLeftQR from "./AsideLeftQR"

export default function AsideLeftTournaments({ }) {

    return (
        <aside className="asideLeft">
            <div className="asideLeft_container">
                <br />
                <header>
                    <h1 className="permanent green">Únete o Crea Tu Propio Torneo de Warzone</h1>
                </header>
                <hr className="separator" />
                <div>
                    <h2 className="permanent gold">¿Buscas Torneos de Warzone?</h2>
                    <br />
                    <p>En <b>torneoswarzone.tk</b> puedes encontrar y unirte a los mejores torneos de Call of Duty: Warzone.</p>
                    <br />
                    <p>Participa en competiciones emocionantes y demuestra tus habilidades.</p>
                    <br />
                    <p><b>¿Tienes un torneo en mente?</b></p>
                    <br />
                    <p>¡Crea tu propio torneo y desafía a otros jugadores!</p>
                    <br /><br />
                    <h3>Beneficios de Participar en Nuestros Torneos:</h3>
                    <br />
                    <ul>
                        <li>- Compite contra los mejores jugadores</li>
                        <li>- Gana premios y reconocimiento</li>
                        <li>- Desarrolla tus habilidades en Warzone</li>
                    </ul>
                </div>
                <AsideLeftQR />
            </div>
        </aside>
    );
}

