
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getComentsById , addComentsById} from "../../services/tournament.service";
import CommentItem from "./CommentItem";
import "./comments.css"

export default function Comments({ }) {
    const [token, setToken] = useState([]);
    const [nick, setNick] = useState([]);
    const [text, setText] = useState([]);
    const [comments, setComments] = useState([]);

    let { id } = useParams();

    useEffect(() => {
        setToken(JSON.parse(localStorage.getItem("token")));
        setNick(JSON.parse(localStorage.getItem("nick")));
        if (id) {
            fetchTournament(id)
        }
    }, []);
    const fetchTournament = async (id) => {
        try {
            const comments = await getComentsById(id);
            setComments(comments);
        } catch (e) {
            console.log(e);
        }
    };
    const pathTournaments = `${process.env.REACT_APP_API_AWS}/tournaments/${id}`;
    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await addComentsById(id, token, nick, text);
        if(res.ok){
            window.location = `/tournaments/${id}`
        }
    };
    return (
        <aside className="comments">
            <h5 className="permanent gold">COMENTARIOS:</h5>
            <br />
            <form action={pathTournaments} className="form_comments" onSubmit={handleSubmit} >
                    <label className="form_comments_label">Escribe un comentario:</label>
                    <br />
                    <textarea className="form_comments_textarea" rows='4' type="text" name={text} placeholder="Escribe un comentario..." required onChange={(e) => setText(e.target.value)} ></textarea>

                <button type="submit" className="btn_section btn_gold">Enviar</button>
            </form>
            <br />
            {comments.map((comment, i) => {
                return <CommentItem key={i} comment={comment} />
            })}
        </aside>
    );
}

