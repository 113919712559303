import { trackPromise } from 'react-promise-tracker';
const pathPlayers = `${process.env.REACT_APP_API_AWS}/players`;

const getPlayers = async () => {
  try {
    const response = await trackPromise(fetch(`${pathPlayers}/`));
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
const getPlayerByToken = async (token) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    const response = await trackPromise(fetch(`${pathPlayers}/player`, {headers: myHeaders}));
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
const getPlayerByEmail = async (email) => {
  try {

    const response = await trackPromise(fetch(`${pathPlayers}/${email}`));
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
const getPlayerByNick = async (nick) => {
  try {
    const response = await trackPromise(fetch(`${pathPlayers}/nick/${nick}`));
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
const getPlayerById = async (id) => {
  try {
    const response = await trackPromise(fetch(`${pathPlayers}/id/${id}`));
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const getPlayersByTeam = async (team) => {
  try {
    const response = await trackPromise(fetch(`${pathPlayers}/list/${team}`));
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

const addPlayer = async (datos) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  try {
    const response = await trackPromise(fetch(pathPlayers, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(datos)
    }));
    const data = await response.json();
    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
const updateNick = async (token, nick) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    myHeaders.append("Content-Type", "application/json");
    const response = await trackPromise(fetch(`${pathPlayers}/nick`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        player: {
          nick: nick,
        }
      }),
    }));
    console.log(response)
    return response;
  } catch (err) {
    console.log(err);
  }
}
const updateFoto = async (token, id, foto) => {
  try {
    if (!foto) {
      return;
    }
    const myHeaders = new Headers();
    myHeaders.append("Authorization", token);
    var formdata = new FormData();
    formdata.append("foto", foto);

    const response = await trackPromise(fetch(`${pathPlayers}/profile/${id}`, {
      headers: myHeaders,
      method: 'POST',
      body: formdata
    }));
    console.log(response)
    return response;
  } catch (err) {
    console.log(err);
  }
}

export { addPlayer, getPlayers, getPlayerByToken, getPlayerByEmail, getPlayersByTeam, getPlayerByNick, getPlayerById, updateNick, updateFoto };
