
import "./loginitem.css"
import React, { useEffect, useState } from "react";
import { getPlayerByNick, updateNick } from "./../../services/player.service"


export default function LoginItem({ text }) {
  const [nick, setNick] = useState([]);
  const [nickOcupado, setNickOcupado] = useState(false);
  const [token, setToken] = useState([]);


  useEffect(() => {
    setToken(JSON.parse(localStorage.getItem("token")));
  }, []);

  document.addEventListener("DOMContentLoaded", (e) => {
    document.getElementById("inputNick").addEventListener("blur", checkNick);
  })
  const checkNick = (e) => {
    e.target.setCustomValidity("")
    if (!e.target.checkValidity()) {
      e.target.setCustomValidity("Solo pueden ser Letras o Números")
      e.target.reportValidity();
      e.target.focus()
    }
  }
  const pathPlayers = `${process.env.REACT_APP_API_AWS}/players/nick`;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const player = await getPlayerByNick(nick);
    if (player.length !== 0) {
      setNickOcupado(true)
    }
    else {
      setNickOcupado(false)
        const res = await updateNick(token,nick);
        if (res.ok) {
          window.location = '/';
        }
    }
  };

  return (

    <div className="login_item border_section">
      <form action={pathPlayers} className="login_item_form" onSubmit={handleSubmit} >
        <label>Introduce tu<b>Nick de Warzone</b> primero para acceder a tu perfil y poder crear torneos.</label>
        <input id="inputNick" type="text" name={nick} placeholder="NickName" required pattern="[A-Za-z0-9]+" onChange={(e) => {
          setNick(e.target.value)
          checkNick(e)
        }}
        />
        {nickOcupado && <p className="login_item_nickocupado">El nick ya está en uso</p>}
        <br /><br />
        <p>¡Recuerda que siempre podras cambiar tu Nick haciendo click sobre él!</p><br />
        <button type="submit" className="btn_section btn_gold">Aceptar</button>
      </form>
    </div>

  );
}

