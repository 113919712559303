
import "./loginitem.css"
import "./joinTeam.css"
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { addPlayerTeam } from "../../services/team.service";
import { checkJoinCodeByName } from "../../services/team.service";


export default function JoinTeam({ text }) {
    const [token, setToken] = useState([]);
    const [joinCode, setJoinCode] = useState([]);
    const [isValid, setIsValid] = useState(true);
    const [name, setName] = useState([]);

    let { team } = useParams();
    let { code } = useParams();

    useEffect(() => {
        setToken(JSON.parse(localStorage.getItem("token")));
        setJoinCode(code);
        setName(team)
    }, []);

    const join = async () => {
        const check = await checkJoinCodeByName(name, joinCode);
        if (check) {
            const res = await addPlayerTeam(name, token);
            if (res.ok) {
                window.location = `/teams/${name}`;
            }
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }
    return (

        <div className="joinTeam border_section">
            <div className="joinTeam_container">
                <h3>{name}</h3>
                <h5>Introduce el código de acceso para unirte.</h5>
                {!isValid && <p className="login_item_nickocupado">¡El Código no es válido!</p>}
                <br />
                <input type="text" name={joinCode} value={joinCode} placeholder="Código" onChange={(e) => { setJoinCode(e.target.value) }} />
                <button onClick={join} className="btn_section btn_gold">Unirme al Equipo</button>
            </div><br />
        </div>

    );
}