import React, { useEffect, useState } from "react";
import "./contacto.css"

export default function Contacto({ }) {


    return (
        <div className="contacto">
        <h1>CONTACTO</h1>
        <br /><br />
        <p>Si tienes alguna pregunta, consulta o sugerencia sobre nuestra aplicación para crear torneos de Call of Duty Warzone, por favor contáctanos a través del siguiente correo electrónico:</p>
        <br />
        <p>Correo de Contacto: 210059@fppiramide.com</p>
        <br />
        <p>Estaremos encantados de ayudarte y responder a tus inquietudes lo antes posible.</p>
        </div>
    );
}

