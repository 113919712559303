
import React, { useEffect, useState } from "react";
import "./formtournament.css"
import { getMaps } from './../../../services/map.service';
import { addTournament, getTournamentByName } from "../../../services/tournament.service";

export default function FormTournament() {
    const [name, setName] = useState([]);
    const [map, setMap] = useState([]);
    const [maxteams, setMaxteams] = useState([]);
    const [numPlayers, setNumPlayers] = useState([]);
    const [pointsBaja, setPointsBaja] = useState([]);
    const [primero, setPrimero] = useState([]);
    const [segundo, setSegundo] = useState([]);
    const [tercero, setTercero] = useState([]);
    const [details, setDetails] = useState([]);
    const [finInscripcion, setFinInscripcion] = useState([]);
    const [fechaComienzo, setFechaComienzo] = useState([]);
    const [numPartidas, setNumPartidas] = useState([]);
    const [maps, setMaps] = useState([]);
    const [token, setToken] = useState([]);
    const [email, setEmail] = useState([]);
    const [nick, setNick] = useState([]);
    const [tournamentNameOcupado, setTournamentNameOcupado] = useState(false);


    useEffect(() => {
        setEmail(JSON.parse(localStorage.getItem("email")));
        setToken(JSON.parse(localStorage.getItem("token")));
        setNick(JSON.parse(localStorage.getItem("nick")));
        setMaxteams(10)
        setFinInscripcion(new Date())
        setFechaComienzo(new Date())
        setNumPartidas(1)
        fetchMaps()
        setNumPlayers("Cuartetos")
        setPointsBaja(1)
        setPrimero(2)
        setSegundo(1.50)
        setTercero(1)
    }, []);

    const fetchMaps = async () => {
        try {
            const maps = await getMaps();
            //console.log(response);
            setMaps(maps);
            setMap(maps[0].name)
        } catch (e) {
            console.log(e);
        }
    };
    const checkDate = (e) => {
        const actual = new Date();
        const fechaElegida = new Date(e.target.value);
        e.target.setCustomValidity("")
        if (e.target.checkValidity()) {
            if (fechaElegida.getTime() <= actual.getTime()) {
                e.target.setCustomValidity("La fecha es anterior al día de hoy")
                e.target.reportValidity();
                e.target.focus()
            }
        }
    }
    const checkDateComienzo = (e) => {
        const fechaElegida = new Date(e.target.value);
        e.target.setCustomValidity("")
        if (e.target.checkValidity()) {
            if (fechaElegida.getTime() <= new Date(finInscripcion).getTime()) {
                e.target.setCustomValidity("La fecha es anterior a la de inscripción")
                e.target.reportValidity();
                e.target.focus()
            }
        }
    }
    const pathTournaments = `${process.env.REACT_APP_API_AWS}/tournaments`;

    const handleSubmit = async (e) => {
        e.preventDefault();
        const tournamentDB = await getTournamentByName(name);

        if (tournamentDB._id) {
            setTournamentNameOcupado(true)
        } else {
            setTournamentNameOcupado(false)
            const tournament = {
                nombre: name,
                mapa: map,
                maxteams: Number(maxteams),
                partida: numPlayers,
                finInscripcion: new Date(finInscripcion),
                fechaComienzo: new Date(fechaComienzo),
                numPartidas: numPartidas,
                reglas: {
                    pointsBaja: Number(pointsBaja),
                    primero: Number(primero),
                    segundo: Number(segundo),
                    tercero: Number(tercero)
                },
                organizadores: [{ email: email, id_activision: nick }],
                details: details,
                isFinished: false
            };
            const res = await addTournament(token, tournament);
            if (res.ok) {
                window.location = '/';
            }
        }
    };

    return (
        <div className="form_tournament_container">
            <form action={pathTournaments} className="form_tournament border_section" onSubmit={handleSubmit} >
                <div className="form_tournament_row">
                    <label>Nombre del Torneo:</label><br />
                    <input type="text" name={name} placeholder="Nombre" required onChange={(e) => setName(e.target.value)} />
                </div>
                {tournamentNameOcupado && <p className="login_item_nickocupado">El nombre ya está en uso</p>}
                <div className="form_tournament_row">
                    <label>Mapa:</label><br />
                    <select name={map} onChange={(e) => setMap(e.target.value)} >
                        {maps.map((map, index) => (
                            <option key={index} value={map.name}>{map.name}</option>
                        ))}
                    </select>
                </div>
                <div className="form_tournament_row">
                    <label>Nº Equipos máximo:</label><br />
                    <input type="number" name={maxteams} placeholder="Nº de equipos máximo" required min="2" max="100" onChange={(e) => setMaxteams(e.target.value)} />
                </div>
                <div className="form_tournament_row">
                    <label>Nº Jugadores por Equipo:</label><br />
                    <select type="number" defaultValue={"10"} name={numPlayers} onChange={(e) => setNumPlayers(e.target.value)} >
                        <option value="Cuartetos" selected>Cuartetos</option>
                        <option value="Trios" >Trios</option>
                        <option value="Duos" >Duos</option>
                        <option value="Individual" >Individual</option>
                    </select>
                </div>
                <div className="form_tournament_row">
                    <label>Número de partidas:</label><br />
                    <select type="number" defaultValue={"1"} name={numPartidas} onChange={(e) => setNumPartidas(e.target.value)} >
                        <option value="1" selected>1</option>
                        <option value="2" >2</option>
                        <option value="3" >3</option>
                        <option value="4" >4</option>
                        <option value="5" >5</option>
                    </select>
                </div>
                <div className="form_tournament_row">
                    <label>Fecha fin Inscripción:</label><br />
                    <input type="datetime-local" name={finInscripcion} placeholder="Fecha final de inscripción" required onChange={(e) => {
                        setFinInscripcion(e.target.value)
                        checkDate(e)
                    }} />
                </div>
                <div className="form_tournament_row">
                    <label>Inicio Torneo:</label><br />
                    <input type="datetime-local" name={fechaComienzo} placeholder="Fecha inicio del torneo" required onChange={(e) => {
                        setFechaComienzo(e.target.value)
                        checkDate(e)
                        checkDateComienzo(e)
                    }} />
                </div>
                <h3>REGLAS DEL TORNEO:</h3>
                <div className="form_tournament_row">
                    <label>Puntos por cada Muerte:</label><br />
                    <select type="number" defaultValue={"1"} name={pointsBaja} onChange={(e) => setPointsBaja(e.target.value)} >
                        <option value="1" selected>1</option>
                        <option value="2" >2</option>
                        <option value="3" >3</option>
                    </select>
                </div>
                <div className="form_tournament_row">
                    <label>Equipo termina 1º:</label><br />
                    <select type="number" defaultValue={"2"} name={primero} onChange={(e) => setPrimero(e.target.value)} >
                        <option value="2" selected>x2</option>
                        <option value="3" >x3</option>
                        <option value="4" >x4</option>
                    </select>
                </div>
                <div className="form_tournament_row">
                    <label>Equipo termina 2º:</label><br />
                    <select type="number" defaultValue={"1'50"} name={segundo} onChange={(e) => setSegundo(e.target.value)} >
                        <option value="1.50" selected>x1'50</option>
                        <option value="1.75" >x1'75</option>
                        <option value="2" >x2</option>
                    </select>
                </div>
                <div className="form_tournament_row">
                    <label>Equipo termina 3º:</label><br />
                    <select type="number" defaultValue={"1"} name={tercero} onChange={(e) => setTercero(e.target.value)} >
                        <option value="1" selected>x1</option>
                        <option value="1.25" >x1'25</option>
                        <option value="1.50" >x1'50</option>
                    </select>
                </div>
                <div className="form_tournament_row">
                    <label>Detalles : </label>
                    <textarea rows='3' placeholder='Detalles del torneo' className="form_tournament_textarea" name={details} required onChange={(e) => setDetails(e.target.value)}></textarea>
                </div>
                <button type="submit" className="btn_section btn_gold">Crear Torneo</button>
            </form>
        </div>

    );
}
