
import React, { useEffect, useState } from "react";
import "./comment.css"

export default function CommentItem({ comment }) {

    const [fecha, setFecha] = useState([]);

    useEffect(() => {
        setFecha(new Date(comment.fecha).toLocaleString());
    }, []);

    return (
        <div className="comment">
            <div className="comment_autor">
                <p className="text">{comment.author}</p>
                <p className="text">&nbsp;{fecha}</p>
            </div>
            <br />
            <p>{comment.text}</p>
            <br />
        </div>
    );
}

