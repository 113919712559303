import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import TournamentList from "./components/tournaments/list/tournamentlist"
import TeamList from "./components/teams/list/TeamList";
import MatchList from "./components/matches/list/MatchList";
import Forms from "./components/forms/Forms";
import FormTournament from "./components/forms/items/FormTournament"
import FormTeam from "./components/forms/items/FormTeam"
import FormMatch from "./components/forms/items/FormMatch";
import TournamentDetail from "./components/tournaments/item/tournamentDetail"
import TeamItemDetail from "./components/teams/item/teamItemDetail"
import ProfileItemDetail from "./components/profiles/item/playerItemDetail"
import FormJoinTournament from "./components/forms/items/FormJoinTournament"
import LoginItem from "./components/popups/loginItem"
import LoginGoogle from "./components/popups/logingoogle"
import JoinTeam from "./components/popups/joinTeam";
import DealerTournament from "./components/popups/dealerTournament";
import Chat from "./components/chats/Chat";
import Privacidad from "./components/politica/privacidad";
import Contacto from "./components/politica/contacto";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="" element={<TournamentList />}></Route>
          <Route path="profile/:id" element={<ProfileItemDetail />}></Route>
          <Route path="newTournament/" element={<FormTournament />}></Route>
          <Route path="tournaments/" element={<TournamentList />}></Route>
          <Route path="tournaments/:id" element={<TournamentDetail />}></Route>
          <Route path="tournaments/join/:id" element={<FormJoinTournament />}></Route>
          <Route path="newTeam/" element={<FormTeam />}></Route>
          <Route path="teams/" element={<TeamList />}></Route>
          <Route path="teams/:name" element={<TeamItemDetail />}></Route>
          <Route path="matches/newMatch/:id" element={<FormMatch />}></Route>
          <Route path="matches/" element={<MatchList />}></Route>
          <Route path="matches/:id" element={<MatchList />}></Route>
          <Route path="newForm" element={<Forms />}></Route>
          <Route path="popup/login" element={<LoginItem />}></Route>
          <Route path="popup/google" element={<LoginGoogle />}></Route>
          <Route path="popup/joinTeam/:team/:code" element={<JoinTeam />}></Route>
          <Route path="popup/dealer/:id/:code" element={<DealerTournament />}></Route>
          <Route path="chat" element={<Chat/>}></Route>
          <Route path="politicaprivacidad" element={<Privacidad/>}></Route>
          <Route path="contacto" element={<Contacto/>}></Route>
        </Route>

        <Route path="*" element={<App />}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
